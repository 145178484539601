/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBdnwTxKc0_SLiX6OglYe12YtFDOfb11lE",
  "appId": "1:398724695007:web:046bd1c89126806d963a6a",
  "authDomain": "schooldog-i-jackson-al.firebaseapp.com",
  "measurementId": "G-LZP3DC0SZ8",
  "messagingSenderId": "398724695007",
  "project": "schooldog-i-jackson-al",
  "projectId": "schooldog-i-jackson-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-jackson-al.appspot.com"
}
